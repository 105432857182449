// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const API_VERSION = 'api/v1/';
//const API_VERSION = 'api/v1.1/';
const API_VERSION = 'api/v1.2/';
export const environment = {
  production: false,
  stage: false,
  local: true,
  appUrl: 'http://localhost:4200/',
  envName: 'dev',
  //API_ENDPOINT: 'https://api.ealarmapp.com/' + API_VERSION,
  API_ENDPOINT: 'http://localhost:5000/'+ API_VERSION,//'https://api.ealarmsos.com/' + API_VERSION,//'http://localhost:5000/'+ API_VERSION,//'https://api.ealarmapp.com/' + API_VERSION, //
};
